@use 'dist/libs/ux/styles/component.utils' as utils;

@mixin color($theme) {

  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');

  $app_color: utils.get-color-from-theme($theme, 'primary', 'app');
  $module_color: utils.get-color-from-theme($theme, 'primary', 'module');
  $feature_color: utils.get-color-from-theme($theme, 'primary', 'feature');
  $task_color: utils.get-color-from-theme($theme, 'primary', 'task');
  $team_color: utils.get-color-from-theme($theme, 'primary', 'team');
  $cost_color: utils.get-color-from-theme($theme, 'primary', 'cost');
  $duration_color: utils.get-color-from-theme($theme, 'primary', 'duration');
  $primary_color: utils.get-color-from-theme($theme, 'primary', 500);
  $accent_color: utils.get-color-from-theme($theme, 'accent', 500);

  app-feature-card {
    background-color: $background_color;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 1);
  }

}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
