@use 'dist/libs/ux/styles/component.utils' as utils;

@mixin color($theme) {

  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');
  $primary_color: utils.get-color-from-theme($theme, 'primary', 500);
  $accent_color: utils.get-color-from-theme($theme, 'accent', 500);

  app-application-card {
    background-color: $background_color;
    color: $foreground_color;
    transition: 0.3s;

    .avatar {
      background-color: $primary_color;
    }

    &:hover {

      app-application-card-avatar {
        .cover {
          transform: scale(1.6);
          transition: 0.3s;
        }

        .image img {
          transform: scale(1.1) translate(0rem);
          transition: 0.3s;
        }
      }

    }

    &.selected {
      background-color: $primary_color;
      color: #ecf0f1;

      .cover {
        transform: scale(1.3);
        transition: 0.3s;
      }
    }
  }

  app-application-card-avatar {
    &:hover {
      .cover {
        transform: scale(1.4) translate(-1rem);
        transition: 0.3s;
      }
    }
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
