@use '../../../styles/component.utils' as utils;

$rounded_border: 50px;
$menu_border: 0px;
@mixin color($theme) {

  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');
  $primary_color: utils.get-color-from-theme($theme, 'primary', 500);

  app-button {

    &.rounded {
      background-color: $primary_color;
      border-radius: $rounded_border;

      .icon {
        color: $background_color;
      }

      .text {
        color: $background_color;
      }

      &:hover {
        background-color: transparentize($primary_color, .2);
      }
    }

    &.tab {
      background-color: transparentize($primary_color, .8);
      border-radius: $rounded_border;

      .icon {
        color: $background_color;
      }

      .text {
        color: $background_color;
      }

      &.selected {
        background-color: $primary_color;
      }

      &:hover {
        background-color: $primary_color;
      }
    }

    &.menu {
      background-color: transparent;
      color: $foreground_color;
      border-radius: $menu_border;

      .icon {
        color: $foreground_color;
      }

      .text {
        color: $foreground_color;
      }

      &.selected {
        background-color: $primary_color;
        color: $background-color;

        .icon {
          color: $background-color;
        }

        .text {
          color: $background-color;
        }

      }

      &:hover {
        background-color: transparentize($primary_color, .2);
      }
    }

    &.inverted {
      .icon {
        color: $foreground_color;
      }

      .text {
        color: $foreground_color;
      }
    }
  }

}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
