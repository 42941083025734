@use 'dist/libs/ux/styles/component.utils' as utils;

@mixin color($theme) {

  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');

  $app_color: utils.get-color-from-theme($theme, 'primary', 'app');
  $module_color: utils.get-color-from-theme($theme, 'primary', 'module');
  $feature_color: utils.get-color-from-theme($theme, 'primary', 'feature');
  $task_color: utils.get-color-from-theme($theme, 'primary', 'task');
  $team_color: utils.get-color-from-theme($theme, 'primary', 'team');
  $cost_color: utils.get-color-from-theme($theme, 'primary', 'cost');
  $duration_color: utils.get-color-from-theme($theme, 'primary', 'duration');
  $selection_color: utils.get-color-from-theme($theme, 'primary', 'selection');
  $primary_color: utils.get-color-from-theme($theme, 'primary', 500);
  $accent_color: utils.get-color-from-theme($theme, 'accent', 500);

  app-circle-card .circle {
    background-color: $background_color !important;
  }

  app-circle-card.module {
    background-color: $module_color;
  }
  app-circle-card.team {
    background-color: $team_color;
  }
  app-circle-card.cost {
    background-color: $cost_color;
  }

  app-circle-card.duration {
    background-color: $duration_color;
  }

  app-circle-card.selected {
    background-color: $selection_color;

    .circle {
      opacity: .8;
    }

  }

}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
