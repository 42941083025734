@use 'sass:map';
@use '@angular/material' as mat;
@use 'dist/libs/ux/styles/component.theme' as ux;
@use 'dist/libs/ux/styles/component.utils' as utils;
@use '../../app/modules/estimate/estimate.theme' as estimate;

@include mat.core();

/* ======== Use themes. In this instance, the themes are imported form the UX kit ======== */
@include mat.all-component-themes(ux.$ngnizr-light-theme);
@include mat.typography-hierarchy(ux.$ngnizr_typography);

.theme-light {
  @include ux.ux-kit-component(ux.$ngnizr-light-theme);
  @include estimate.all-components(ux.$ngnizr-light-theme);

}

.theme-dark {
  @include mat.all-component-colors(ux.$ngnizr-dark-theme);
  @include ux.ux-kit-component(ux.$ngnizr-dark-theme);
  @include estimate.all-components(ux.$ngnizr-dark-theme);

}


