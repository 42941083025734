@use '../../../styles/component.utils' as utils;

@mixin color($theme) {

  app-icon-button {

    &.simple {
      color: utils.get-color-from-theme($theme, 'primary', 'foreground');

      &:hover > i {
        font-weight: bold;
      }

    }

    &.material {
      background-color: utils.get-color-from-theme($theme, 'primary', 500);
      color: utils.get-color-from-theme($theme, 'primary', 'background');
      border-radius: 50px;

      &:hover {
        background-color: transparentize(utils.get-color-from-theme($theme, 'primary', 500), .2);
      }
    }

    &.circular {
      background-color: utils.get-color-from-theme($theme, 'primary', 500);
      color: utils.get-color-from-theme($theme, 'primary', 'background');
      border-radius: 50px;
      padding: .5rem;
      margin-left: 4px;
      margin-right: 4px;

      &:hover {
        background-color: transparentize(utils.get-color-from-theme($theme, 'primary', 500), .2);
      }
    }
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
