@use '@angular/material' as mat;
@import 'material-icons/iconfont/material-icons.css';

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

.long {
  width: 500px;
}

.mat-tooltip.tooltip {
  background-color: red;
  width: 500px !important;
  height: 500px !important;
}

.mat-bottom-sheet-container, .mat-bottom-sheet-container-xlarge {
  width: 100vw !important;
  min-width: 100vw !important;
}


.max-width {
  width: 1000vw;
  max-width: 100vw;
  min-width: 100vw !important;
}

.toast-container {
  .ngx-toastr {
    box-shadow: none !important;
  }
}
