@use '../../../styles/component.utils' as utils;

@mixin color($theme) {
  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');
  $primary_color: utils.get-color-from-theme($theme, 'primary', 500);

  app-selection-indicator {
    background-color: transparent;
    border: 1px dashed $foreground_color;
    border-radius: 30px;

    &.selected {
      background-color: $primary_color;

      i {
        color: $background_color;
      }
    }
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
