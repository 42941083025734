@use '../../../styles/component.utils' as utils;

@mixin color($theme) {
  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');

  $app_color: utils.get-color-from-theme($theme, 'primary', 'app');
  $module_color: utils.get-color-from-theme($theme, 'primary', 'module');
  $feature_color: utils.get-color-from-theme($theme, 'primary', 'feature');
  $task_color: utils.get-color-from-theme($theme, 'primary', 'task');
  $team_color: utils.get-color-from-theme($theme, 'primary', 'team');
  $cost_color: utils.get-color-from-theme($theme, 'primary', 'cost');
  $duration_color: utils.get-color-from-theme($theme, 'primary', 'duration');
  $selection_color: utils.get-color-from-theme($theme, 'primary', 'selection');
  $primary_color: utils.get-color-from-theme($theme, 'primary', 500);
  $accent_color: utils.get-color-from-theme($theme, 'accent', 500);

  // Get the is-dark property directly from the theme
  $is-dark: map-get(map-get($theme, color), is-dark);

  // Create two alternating row background colors for the dark theme
  $row_bg_1: mix($background_color, $foreground_color, 85%);  // Slightly lighter than background
  $row_bg_2: mix($background_color, $foreground_color, 90%);  // Very subtle difference

  // Create a lighter variation of the background color for the general background
  $general_bg: mix($background_color, $foreground_color, 95%);  // Lighter than background

  // Determine text color based on background (ensures contrast)
  $text_color: $foreground_color;

  // Set CSS variables at root level for use in chart styling
  :root {
    --gantt-primary-color: #{$primary_color};
    --gantt-background-color: #{$background_color};
    --gantt-foreground-color: #{$foreground_color};
  }

  app-gantt-chart {
    color: $primary_color;
    border-radius: 12px;

    google-chart {
      background-color: $background_color;
      color: $primary_color;

      // Apply theme across all chart elements
      .google-visualization-chart-table {
        border-color: $primary_color;
      }

      .google-visualization-chart-action-container {
        background-color: $background_color;
        color: $primary_color;
      }

      .google-visualization-tooltip {
        background-color: $background_color;
        color: $text_color;
        border-color: $primary_color;
      }

      // All SVG text elements
      text,
      tspan {
        fill: $text_color !important;
      }

      .app-bar,
      .module-bar,
      .feature-bar,
      .task-bar,
      .team-bar,
      rect.gantt-bar {
        fill: $primary_color !important;
        stroke: $primary_color !important;
      }

      // Target the row background rectangles and other SVG elements
      svg {
        // Style for arrow paths - connecting lines between items
        path {
          stroke: $foreground_color !important;
          fill: none !important;
        }

        // Style for arrowheads (which are usually paths with fill)
        path[d*="Z"] {
          fill: $foreground_color !important;
          stroke: $foreground_color !important;
        }

        // Alternative targeting for arrows if the above doesn't work
        g > path[d*="M"][d*="L"] {
          stroke: $foreground_color !important;
        }

        // General background - target the last rectangle
        rect:last-of-type {
          fill: $general_bg !important;
        }

        // Alternative selectors for the general background
        // If the last-of-type doesn't work correctly
        rect[width="100%"],
        rect[x="0"][y="0"][width$="00"][height$="00"] {
          fill: $general_bg !important;
        }

        // Row background rectangles - these are usually the large rectangles spanning the row width
        rect[height="40"],
        rect[height="41"],
        rect[height="42"],
        rect[height="43"],
        rect[height="44"],
        rect[height="45"],
        rect[height="46"],
        rect[height="47"],
        rect[height="48"],
        rect[height="49"],
        rect[height="50"] {
          &:nth-of-type(odd) {
            fill: $row_bg_1 !important;
          }

          &:nth-of-type(even) {
            fill: $row_bg_2 !important;
          }
        }

        // Text styling
        text {
          fill: $text_color !important;
          // Add a very subtle text shadow to improve readability in both themes
          text-shadow: 0px 0px 1px rgba(if($is-dark, 0, 255),
            if($is-dark, 0, 255),
            if($is-dark, 0, 255), 0.3) !important;
        }
      }
    }
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);

  app-gantt-chart {
    google-chart {
     // font-family: utils.font-family($typography-config);

      .google-visualization-chart-title {
        //font-size: utils.font-size($typography-config, 'title');
        //font-weight: utils.font-weight($typography-config, 'title');
        //line-height: utils.line-height($typography-config, 'title');
      }

      .google-visualization-chart-table {
       // font-size: utils.font-size($typography-config, 'body-1');
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
