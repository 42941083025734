@use 'sass:map';
@use '@angular/material' as mat;
@use '../components/buttons/button/button-theme' as button;
@use '../components/buttons/icon/icon-theme' as icon;
@use '../components/navigation/appbar/appbar-theme' as appbar;
@use '../components/content/card/card-theme' as card;
@use '../components/buttons/selection-indicator/indicator-theme' as indicator;
@use '../components/content/stepper/stepper-theme' as stepper;
@use '../components/inputs/input/input-theme' as input;
@use '../components/inputs/input-validation-errors/input-validation-errors-theme' as inputValidation;
@use '../components/inputs/input-multitext/input-multitext-theme' as multiInput;
@use '../components/inputs/input-chips/chips-theme' as chips;
@use '../components/inputs/input-dropdown-autocomplete/dropdown-autocomplete-theme' as dropdownAutocomplete;
@use '../components/inputs/input-radio/radio-theme' as radio;
@use '../components/inputs/input-switch/input-switch-theme' as switch;
@use '../components/inputs/input-textarea/textarea-theme' as textarea;
@use '../components/content/surface/surface-theme' as surface;
@use '../components/content/tree/tree-theme' as tree;
@use '../components/content/material/material-theme' as material;
@use '../components/content/gantt/_gantt-chart-component-theme' as gantt;

/* ======== Color palettes ======== */
$app_background_color: #ecf0f1;
$app_foreground_color: #28292A;

$app_app_color: #98353C;
$app_module_color: #2980B9;
$app_feature_color: #F1C40F;
$app_task_color: #16A085;
$app_team_color: #8E44AD;
$app_cost_color: #F1C40F;
$app_duration_color: #245e6e;
$app_selection_color: #2ecc71;

$app-primary-light: mat.define-palette((
  50 : #e5edef,
  100 : #bfd1d6,
  200 : #94b3bb,
  300 : #69949f,
  400 : #487d8b,
  500 : #286676,
  600 : #245e6e,
  700 : #1e5363,
  800 : #184959,
  900 : #0f3846,
  A100 : #80daff,
  A200 : #4dcbff,
  A400 : #1abcff,
  A700 : #00b5ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ),
  background: $app_background_color,
  foreground: $app_foreground_color,
  app: $app_app_color,
  module: $app_module_color,
  feature: $app_feature_color,
  task: $app_task_color,
  team: $app_team_color,
  cost: $app_cost_color,
  duration: $app_duration_color,
  selection: $app_selection_color
));
$app-primary-dark: mat.define-palette((
  50 : #e5edef,
  100 : #bfd1d6,
  200 : #94b3bb,
  300 : #69949f,
  400 : #487d8b,
  500 : #286676,
  600 : #245e6e,
  700 : #1e5363,
  800 : #184959,
  900 : #0f3846,
  A100 : #80daff,
  A200 : #4dcbff,
  A400 : #1abcff,
  A700 : #00b5ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ),
  background: $app_foreground_color,
  foreground: $app_background_color,
  app: $app_app_color,
  module: $app_module_color,
  feature: $app_feature_color,
  task: $app_task_color,
  team: $app_team_color,
  cost: $app_cost_color,
  duration: $app_duration_color,
  selection: $app_selection_color
));
$app-accent: mat.define-palette((
  50 : #fff4e0,
  100 : #ffe3b3,
  200 : #ffd080,
  300 : #ffbd4d,
  400 : #ffae26,
  500 : #C55C51,
  600 : #ff9800,
  700 : #ff8e00,
  800 : #ff8400,
  900 : #ff7300,
  A100 : #ffffff,
  A200 : #fff7f2,
  A400 : #ffd8bf,
  A700 : #ffc9a6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #202020,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ),
));
$app-warn: mat.define-palette(mat.$red-palette);

/* ======== Typography ======== */

$ngnizr_typography: mat.define-typography-config(
  $font-family: 'monospace'
);

/* ======== Theming: Appmake ======== */
$ngnizr-light-theme: mat.define-light-theme((
  color: (
    primary: $app-primary-light,
    accent: $app-accent,
    warn: $app-warn,
    is-dark: false,
  ),
  typography: $ngnizr_typography,
  density: 0,
));


$ngnizr-dark-theme: mat.define-dark-theme((
  color: (
    primary: $app-primary-dark,
    accent: $app-accent,
    warn: $app-warn,
    is-dark: true,
  ),
  typography: $ngnizr_typography,
  density: 0,
));

@mixin ux-kit-component($theme) {
  $dedupe-key: 'ux-kit';
  @include button.theme($theme);
  @include icon.theme($theme);
  @include appbar.theme($theme);
  @include card.theme($theme);
  @include indicator.theme($theme);
  @include stepper.theme($theme);
  @include input.theme($theme);
  @include multiInput.theme($theme);
  @include chips.theme($theme);
  @include dropdownAutocomplete.theme($theme);
  @include radio.theme($theme);
  @include switch.theme($theme);
  @include inputValidation.theme($theme);
  @include textarea.theme($theme);
  @include surface.theme($theme);
  @include tree.theme($theme);
  @include material.theme($theme);
  @include gantt.theme($theme);
}

