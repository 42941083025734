@use 'sass:map';
@use '@angular/material' as mat;
@use 'dist/libs/ux/styles/component.utils' as utils;

@use './components/application-card/application-card-theme' as application-card;
@use './components/application-details-card/application-details-card-theme' as application-details;
@use './components/circle-card/circle-card-theme' as circle-card;
@use './components/feature-card/feature-card-theme' as feature-card;

@mixin all-components($theme) {
  $dedupe-key: 'estimate';
  @include application-card.theme($theme);
  @include application-details.theme($theme);
  @include circle-card.theme($theme);
  @include feature-card.theme($theme);

  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');

  .p-accordion {
    background-color: transparent !important;

    .p-accordion-header {
      background-color: transparent !important;
      border: none !important;

      .p-accordion-header-link {
        border: none !important;
        padding: 0 !important;
        background-color: transparent !important;
        font-weight: initial;

        :focus {
          box-shadow: none !important;
          outline: none !important;
        }
      }

      &:not(.p-disabled) .p-accordion-header-link:focus {
        box-shadow: none !important;
      }

      .p-icon-wrapper {
        display: none !important;
      }

    }

    .p-accordion-content {
      margin-top: -5px;
      background-color: $background_color !important;
      color: $foreground_color !important;
      border: none;
    }
  }

}



