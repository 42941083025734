@use '../../../styles/component.utils' as utils;


@mixin color($theme) {

  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');
  $primary_color: utils.get-color-from-theme($theme, 'primary', 500);
  $accent_color: utils.get-color-from-theme($theme, 'accent', 500);
  $global_background: utils.get-color-from-theme($theme, background, background);
  $warn_color: utils.get-color-from-theme($theme, 'warn', 500);
  $input_icon_color: #626262;

  app-input-multitext {

    mat-form-field {

      .mat-icon {
        color: $primary_color;
      }

    }

    &.ng-invalid.ng-touched {
      mat-form-field {
        .mat-icon {
          color: $warn_color;
        }

        .mat-form-field-underline {
          display: none;
        }

      }
    }


    .chip-container {
      .chip {
        background-color: $primary_color;
        color: $background_color;

        &:hover {
          background-color: transparentize($primary_color, .2);
        }
      }
    }

  }


}


@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
