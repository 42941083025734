@use 'sass:map';
@use '@angular/material' as mat;

@function get-color-from-theme($theme, $palette, $variant) {
  // Get the color configuration from the theme
  $color-config: mat.get-color-config($theme);
  // Get the color palette that is wanted
  $primary-palette: map.get($color-config, $palette);
  // Get the specific color variant from the palette
  $color: mat.get-color-from-palette($primary-palette, $variant);

  @return $color;
}

@function get-background-color($theme) {
  // Get the color configuration from the theme
  $color-config: mat.get-color-config($theme);
  // Get the color palette that is wanted
  $background-palette: map.get($color-config, background);
  // Get the specific color variant from the palette
  $color: mat.get-color-from-palette($background-palette, background);

  @return $color;
}

/* Retrieves the color configuration from the specific theme */
@function get-color-config($theme) {
  @return mat.get-color-config($theme);
}

/* Retrieves the typography configuration from the specific theme */
@function get-typography-config($theme) {
  @return mat.get-typography-config($theme);
}

@mixin typography-for($theme, $variant) {
  $config: get-typography-config($theme);

  font-size: mat.font-size($config, $variant);
  font-family: mat.font-family($config, $variant);
  font-weight: mat.font-weight($config, $variant);
  line-height: mat.line-height($config, $variant);
  letter-spacing: mat.letter-spacing($config, $variant);
}


/************** RESOLUTIONS *****************/

$resolutions: (
    "xs": (
        "min": 0px,
        "max": 300px
    ),
    "sm": (
        "min": 600px,
        "max": 959px
    ),
    "md": (
        "min": 960px,
        "max": 1279px
    ),
    "lg": (
        "min": 1280px,
        "max": 1919px
    ),
    "xl": (
        "min": 1920px,
        "max": 5000px
    ),
);

@function screen($screen, $minMax) {
  @return map-get(map-get($resolutions, $screen), $minMax);
}
