@use '../../../styles/component.utils' as utils;


@mixin color($theme) {

  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');
  $primary_color: utils.get-color-from-theme($theme, 'primary', 500);
  $accent_color: utils.get-color-from-theme($theme, 'accent', 500);
  $global_background: utils.get-color-from-theme($theme, background, background);
  $warn_color: utils.get-color-from-theme($theme, 'warn', 500);
  $switch_off_color: #E0E0E0;
  $switch_element_color: #79747E;
  $switch_border_radius: 48px;
  $switch_on_font: 24px;
  $switch_off_font: 10px;

  app-input-switch {

    .switch {
      border: 1px solid $switch_element_color;
      background-color: $switch_off_color;

      .mdc-form-field {
        height: 25px;

        > label {
          margin-left: 1rem;
        }
      }

      // Normal switch
      .mdc-switch {

        .mdc-switch__track {
          display: none;
        }

        &.mdc-switch--unselected {
          .mdc-switch--handle {
            width: $switch_off_font !important;
            height: $switch_off_font !important;
          }

          .mdc-switch__handle-track {
            display: $switch_off_color;

            &:hover {
              background-color: $switch_off_color;
            }
          }

          .mdc-switch__icons {
            background-color: $switch_element_color;
            border-radius: 48px;

            .mdc-switch__icon--off {
              display: none;
            }
          }

          &:hover {

            .mdc-switch__handle-track {
              background-color: $switch_off_color;
            }

            .mdc-switch__track, .mdc-switch__track::after {
              background-color: $switch_off_color;
            }
          }
        }

      }

      // Checked switch
      &.mat-mdc-slide-toggle-checked {
        border: 1px solid $primary_color;
        transition: background-color 0.1s ease;
        background-color: $primary_color;


        .mdc-switch {

          &.mdc-switch--checked {

            .mdc-switch__handle-track:hover {
              background-color: $primary_color;
            }

            .mdc-switch__handle-track {
              background-color: $primary_color;
            }

            .mdc-switch__track, .mdc-switch__track::after {
              background-color: $primary_color;
            }

            .mdc-switch__icon {
              fill: $primary_color;
            }

            .mdc-switch__handle {
              transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
              transition-duration: 75ms;
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-delay: 0ms;
              transition-property: transform;
              width: $switch_on_font;
              height: $switch_on_font;
              border-radius: $switch_border_radius;
            }

            .mdc-switch__icons {
              background-color: white;
              border-radius: $switch_border_radius;
            }
          }

          &:hover {
            .mdc-switch__handle-track {
              background-color: $primary_color;
            }

            .mdc-switch__track, .mdc-switch__track::after {
              background-color: $primary_color;
            }
          }
        }
      }
    }
  }
}


@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);

}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
