@use '../../../styles/component.utils' as utils;


@mixin color($theme) {

  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');
  $primary_color: utils.get-color-from-theme($theme, 'primary', 500);
  $accent_color: utils.get-color-from-theme($theme, 'accent', 500);
  $global_background: utils.get-color-from-theme($theme, background, background);
  $warn_color: utils.get-color-from-theme($theme, 'warn', 500);
  $input_icon_color: #626262;

  app-input {

    .input-icon {
      color: $primary_color;
    }

    &.ng-valid {
      .input-icon {
        color: $primary_color;
      }
    }

    &.ng-invalid .ng-touched:not(.ng-pristine) {
      .input-icon {
        color: $warn_color;
      }

      .mat-mdc-line-ripple::before {
        border-bottom-color: $primary_color;
      }

      //TODO Should be moved under number-field (was not working)
      .step-up-arrow, .step-down-arrow {
        color: $warn_color;
      }

    }

    .text-field {
      .text-icon {
        color: $primary_color;
        padding: 12px;
        font-size: 20px;
      }

      &.ng-valid {
        .input-icon {
          color: $primary_color;
        }

        .text-icon {
          color: $primary_color;
          font-weight: bold;
        }
      }

      &.ng-invalid .ng-touched:not(.ng-pristine) {
        .text-icon {
          color: $warn_color;
          font-weight: bold;
        }
      }

    }

    .number-field {

      /** NUMBER INPUT MACHINATIONS  **/
      // hide step buttons on Firefox
      input[type=number] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
        width: calc(100% - 18px);

        // hide step buttons on Chrome/Safari
        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }
      }

      // place step-arrow-wrapper to the right of textbox
      .step-arrow-wrapper {
        width: 18px;
        padding: 12px;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;;
      }

      // step arrow style made to match mat-select arrow
      .step-up-arrow, .step-down-arrow {
        cursor: pointer;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        margin: 4px 4px;
        color: $primary_color;
      }

      .step-up-arrow {
        border-bottom: 5px solid;
        margin-bottom: 5px;
      }

      .step-down-arrow {
        border-top: 5px solid;
      }

      .mat-form-field.mat-focused.mat-primary .step-up-arrow,
      .mat-form-field.mat-focused.mat-primary .step-down-arrow {
        color: $primary_color;
      }

      .mat-form-field.mat-focused.mat-accent .step-up-arrow,
      .mat-form-field.mat-focused.mat-accent .step-down-arrow {
        color: $primary_color;
      }

      .mat-form-field.mat-form-field-invalid .step-up-arrow,
      .mat-form-field.mat-form-field-invalid .step-down-arrow {
        color: $warn_color !important;
      }

      .mat-form-field.mat-form-field-disabled .step-up-arrow,
      .mat-form-field.mat-form-field-disabled .step-down-arrow {
        cursor: default;
      }

      &.ng-invalid .ng-touched:not(.ng-pristine) {
        .step-up-arrow, .step-down-arrow {
          color: $warn_color !important;
        }
      }

    }

    .date-field {

      &.ng-pristine {
        .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
          border-bottom-color: $primary_color;
        }
      }
    }

  }
}


@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
