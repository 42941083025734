@use '../../../styles/component.utils' as utils;


@mixin color($theme) {

  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');
  $primary_color: utils.get-color-from-theme($theme, 'primary', 500);
  $accent_color: utils.get-color-from-theme($theme, 'accent', 500);
  $global_background: utils.get-color-from-theme($theme, background, background);

  app-stepper {
    background-color: $background_color;

    .mat-horizontal-stepper-header-container {
      background-color: $background_color;
    }

    .mat-horizontal-content-container {
      background-color: $background_color;
    }

    mat-stepper {
      background-color: $background_color;
    }

    .step-content {
    }

    .step-actions {
      height: 56px;
      background-color: $background_color;
      padding-bottom: 1rem;
      padding-right: 1rem;
      padding-left: 1rem;
    }

  }
}


@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
