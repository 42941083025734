@use '../../../styles/component.utils' as utils;


@mixin color($theme) {

  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');
  $primary_color: utils.get-color-from-theme($theme, 'primary', 500);
  $accent_color: utils.get-color-from-theme($theme, 'accent', 500);
  $global_background: utils.get-color-from-theme($theme, background, background);
  $warn_color: utils.get-color-from-theme($theme, 'warn', 500);
  $input_icon_color: #626262;

  app-input-radio {

    .vertical {

      .mdc-evolution-chip-set__chips {
        flex-flow: column;
        min-width: auto !important;
      }
    }

    app-form-error-list {
      .errors {
        margin-top: 0 !important;
      }
    }
  }
}


@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
