@use '../../../styles/component.utils' as utils;

@mixin color($theme) {

  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');

  app-appbar::ng-deep {

    mat-toolbar {

      .language-item {
        padding: 1rem !important;
        margin: 0 !important;
      }

      .menu {
        background-color: transparentize($background_color, .8) !important;

        app-button {
          .icon {
            color: white;
          }

          .text {
            color: $background_color;
          }

          .options {
            .icon {
              color: white !important;
            }
          }
        }
      }
    }

    .hamburger {
      .icon {
        color: $background_color;
      }
    }

  }

  .language-item {
    padding: 1rem;
    margin: 0;
  }

}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
