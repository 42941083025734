@use '../../../styles/component.utils' as utils;


@mixin color($theme) {

  $background_color: utils.get-color-from-theme($theme, 'primary', 'background');
  $foreground_color: utils.get-color-from-theme($theme, 'primary', 'foreground');
  $primary_color: utils.get-color-from-theme($theme, 'primary', 500);
  $accent_color: utils.get-color-from-theme($theme, 'accent', 500);
  $global_background: utils.get-color-from-theme($theme, background, background);
  $warn_color: utils.get-color-from-theme($theme, 'warn', 500);
  $icon_font_size: 30px;

  app-input-dropdown-autocomplete {

    mat-form-field {
      mat-icon {
        color: $primary_color;
        font-size: $icon_font_size;
        margin-right: 5px;
      }
    }

    &.ng-valid {
      mat-form-field {
        mat-icon {
          font-size: $icon_font_size;
        }
      }
    }
  }
}


@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: utils.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: utils.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: utils.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
